<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">配制</h3>
    <div class="detail">
      <template v-if="!showRecord">
        <div class="flex  backReturn">
          <h4 class="title">建模信息</h4>
          <el-button size="small" plain class="return" @click="addRecord" v-if="state==null||state==0">新增接种信息
          </el-button>
          <el-button size="small" plain class="return" v-if="endMode" @click="endModeDia">结束建模</el-button>
        </div>
        <el-table
            border
            :data="tableData"
            class="record-table"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
            max-height="calc(100vh - 450px)">
          <el-table-column
              prop="vaccSite"
              label="接种方式"
              show-overflow-tooltip>
          </el-table-column>
          <el-table-column
              prop="cellType"
              label="试剂种类" show-overflow-tooltip>
          </el-table-column>
          <el-table-column
              prop="comparison"
              label="溶媒对照" show-overflow-tooltip>
          </el-table-column>
          <el-table-column
              prop="cellCount" width="170"
              label="接种目标浓度">
          </el-table-column>
          <el-table-column
              prop="cellDensity" width="170"
              label="配制剂量浓度">
          </el-table-column>
          <el-table-column
              prop="vaccVolume" width="140"
              label="接种体积(μl)">
          </el-table-column>
          <el-table-column
              width="150"
              label="接种动物数量(只)">
            <el-table-column
                prop="femaleNum"
                label="雌性"
                min-width="90">
            </el-table-column>
            <el-table-column
                prop="maleNum"
                label="雄性"
                min-width="90">
            </el-table-column>
          </el-table-column>
          <template v-if="state==null || state==0">
            <el-table-column
                prop="condition" width="140" show-overflow-tooltip
                label="配制条件">
            </el-table-column>
            <el-table-column
                width="140"
                label="供试品配置有效期">
              <template slot-scope="scope">
                {{ scope.row.expiryDate | formatDay }}
              </template>
            </el-table-column>
            <el-table-column
                prop="residualHandle" width="150" show-overflow-tooltip
                label="剩余液处理方法">
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作" width="120">
              <template slot-scope="scope">
                <div v-if="state==0" class="editColor">
                  <el-button size="small" type="text" @click="editRecord(scope.row)">编辑</el-button>
                  <el-button size="small" type="text" @click="deleteA(scope.row.id)">删除</el-button>
                </div>
                <div v-else>
                  <el-button size="small" type="text" @click="editRecord(scope.row)">编辑</el-button>
                </div>
              </template>
            </el-table-column>
          </template>
          <template v-if="state==1">
            <el-table-column
                label="状态" width="110">
              <template slot-scope="scope">
                <el-button size="small" class="experimentCo" v-if="scope.row.step==0">待复核</el-button>
                <el-button size="small" class="finished" v-else-if="scope.row.step==1">复核通过</el-button>
                <el-button size="small" class="approval" v-else>复核退回</el-button>
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作" width="100">
              <template slot-scope="scope">
                <div class="editColor">
                  <el-button size="small" type="text" v-if="scope.row.step==0&&isCheck"
                             @click="toCheck(scope.row.id,1)">复核
                  </el-button>
                  <el-button size="small" type="text" v-if="scope.row.step==2&&!isCheck" @click="editRecord(scope.row)">
                    修改
                  </el-button>
                  <el-button size="small" type="text" v-if="scope.row.step==1||scope.row.step==2"
                             @click="toCheck(scope.row.id,1)">详情
                  </el-button>
                  <el-button size="small" type="text" v-else @click="toCheck(scope.row.id,0)">详情</el-button>
                </div>
              </template>
            </el-table-column>
          </template>
        </el-table>

        <!-- 复核表单-->
        <template v-if="state==null||state==0">
          <h4 class="title" style="margin-top:16px">指定复核人</h4>
          <el-form ref="vaccinaReview" size="small" :model="vaccinaReview" class="addForm" label-suffix=":"
                   label-width="100px">
            <el-form-item label="复核人员">
              <el-radio-group v-model="vaccinaReview.reviewerName" class="radioGroup">
                <el-radio v-for="item in userList" :label="item.name" :key="item.userId"
                          @click.native="getAppId(item.userId,item.name)"
                > {{ item.name }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <div class="fromSave">
              <el-button type="primary" size="small" @click="saveRecord(1)">配制完成</el-button>
              <el-button plain type="primary" size="small" @click="saveRecord(0)">保存</el-button>
            </div>
          </el-form>
        </template>
        <!--        指定复核人-->
        <template v-else-if="state==1&&!isCheck">
          <h4 class="title" style="margin-top:16px">指定复核人</h4>
          <div class="desc">
            <span>复核人员：{{ vaccinaReview.reviewerName }}</span>
          </div>
        </template>
        <template v-else>
          <h4 class="title" style="margin-top:16px">细胞悬液配制人</h4>
          <div class="desc">
            <span>配置人：{{ preparer.name }}</span>
            <span>配置时间：{{ preparer.time  | formatDay }}</span>
          </div>
        </template>
      </template>


      <!--      详情导出-->
      <modelingTable
          :preparationInfo="preparationInfo" :id="id"
          :checkState="checkState" @changeShow="changeShow"
          v-if="showRecord"></modelingTable>
    </div>
    <!--    新增编辑-->
    <el-dialog :close-on-click-modal="false"
               :title="action+'接种方式'"
               class="flex dialogForm"
               top="0"
               :visible.sync="dialogVisible"
               width="720px">
      <el-form ref="vaccination" size="small" :model="vaccination" class="addForm" label-suffix=":"
               label-width="140px">
        <el-form-item label="接种方式" prop="vaccSite" :rules="[{ required: true, message: '请输入接种方式', trigger: 'blur' }]">
          <el-input v-model="vaccination.vaccSite" placeholder="请输入接种方式" clearable class="smallWidth"></el-input>
        </el-form-item>
        <div class="flex head">
          <el-form-item label="试剂种类" prop="cellType" :rules="[{ required: true, message: '请输入试剂种类', trigger: 'blur' }]">
            <el-input v-model="vaccination.cellType" placeholder="请输入试剂种类" clearable class="smallWidth"></el-input>
          </el-form-item>
          <el-form-item label="溶媒对照" prop="comparison"
                        :rules="[{ required: true, message: '请输入溶媒对照', trigger: 'blur' }]">
            <el-input v-model="vaccination.comparison" placeholder="请输入溶媒对照" clearable
                      class="smallWidth"></el-input>
          </el-form-item>
        </div>
        <div class="flex head">
          <el-form-item label="接种目标浓度" prop="cellCount"
                        :rules="[{ required: true, message: '请输入接种目标浓度', trigger: 'blur' }]">
            <el-input v-model="vaccination.cellCount" placeholder="请输入接种目标浓度" class="smallWidth"></el-input>
          </el-form-item>
          <el-form-item label="配制剂量浓度" prop="cellDensity"
                        :rules="[{ required: true, message: '请输入配制剂量浓度', trigger: 'blur' }]">
            <el-input v-model="vaccination.cellDensity" placeholder="请输入配制剂量浓度" class="smallWidth">
              <!--              <span slot="suffix">个细胞/ml</span>-->
            </el-input>
          </el-form-item>
        </div>
        <el-form-item label="接种体积" prop="vaccVolume" :rules="[{ required: true, message: '请输入接种体积', trigger: 'blur' }]">
          <el-input v-model="vaccination.vaccVolume" placeholder="请输入接种体积" class="smallWidth">
            <span slot="suffix">μl</span>
          </el-input>
        </el-form-item>
        <div class="flex head">
          <el-form-item label="接种雌性动物数量" prop="femaleNum"
                        :rules="[{ required: true, message: '请输入雌性动物数量', trigger: 'blur' }]">
            <el-input v-model="vaccination.femaleNum" placeholder="请输入接种雌性动物数量" type="number" :min="0"
                      class="smallWidth">
              <span slot="suffix">只</span>
            </el-input>
          </el-form-item>
          <el-form-item label="接种雄性动物数量" prop="maleNum"
                        :rules="[{ required: true, message: '请输入雄性动物数量', trigger: 'blur' }]">
            <el-input v-model="vaccination.maleNum" placeholder="请输入接种雄性动物数量" class="smallWidth">
              <span slot="suffix">只</span>
            </el-input>
          </el-form-item>
        </div>
        <el-form-item label="配制条件" prop="condition" :rules="[{ required: true, message: '请输入配制条件', trigger: 'blur' }]">
          <el-input v-model="vaccination.condition" placeholder="请输入配制条件"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="供试品配制有效期">
          <el-date-picker
              size="small"
              v-model="vaccination.expiryDate"
              type="date"
              placeholder="请输入供试品配制有效期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="剩余液处理方法" prop="residualHandle"
                      :rules="[{ required: true, message: '请输入剩余液处理方法', trigger: 'blur' }]">
          <el-input v-model="vaccination.residualHandle" placeholder="请输入剩余液处理方法"
                    clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="submitForm('vaccination')">确认</el-button>
          <el-button size="small" plain type="primary" @click="dialogVisible=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>

</template>

<script>

import {mapState} from "vuex";

export default {
  name: "modeling",
  props: ["id", 'status'],
  data() {
    return {
      action: '新增',
      tableData: [],
      dialogVisible: false,
      showRecord: false,
      vaccination: {
        cellCount: null,
        cellDensity: null,
        cellType: "",
        comparison: "",
        condition: "",
        expiryDate: "",
        femaleNum: null,
        maleNum: null,
        residualHandle: "",
        vaccSite: "",
        vaccVolume: ""
      },
      // 复核人员
      vaccinaReview: {
        reviewer: null,
        reviewerName: '',
      },
      userList: [],
      // 获取提交保存状态
      state: null,
      // 详情
      preparationInfo: {},
      checkState: 0,
      //结束建模
      endMode: false,
      // 是不是复核人
      isCheck: false,
      // 配置人
      preparer: {
        name: '',
        time: ''
      },
    }
  },
  mounted() {
    this.$get('/subject/member/list/' + this.id).then(res => {
      if (res.status == 200) {
        this.userList = res.data
      }
    })
    // 悬浮液配置中
    if (this.status >= 20) {
      this.getModeling()
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  methods: {
    // 新增
    addRecord() {
      this.action = '新增'
      this.dialogVisible = true
      this.vaccination = {}
    },
    // 编辑
    editRecord(row) {
      this.dialogVisible = true
      this.action = '编辑'
      if (this.state == null) {
        this.vaccination = row
      } else {
        this.vaccination = {
          id: row.id,
          subId: row.subId,
          cellCount: row.cellCount,
          cellDensity: row.cellDensity,
          cellType: row.cellType,
          comparison: row.comparison,
          condition: row.condition,
          expiryDate: row.expiryDate,
          femaleNum: row.femaleNum,
          maleNum: row.maleNum,
          residualHandle: row.residualHandle,
          vaccSite: row.vaccSite,
          vaccVolume: row.vaccVolume,
          reviewer: row.reviewer,
          reviewName: row.reviewName,
        }
      }
    },
    // 指定复核人员
    getAppId(id, name) {
      this.vaccinaReview.reviewer = id
      this.vaccinaReview.reviewerName = name
    },
    // 保存或者提交获取整个详情
    getModeling() {
      this.$get('/model/sus/' + this.id).then(res => {
        if (res.status == 200) {
          // 悬浮液配置中 -- 保存状态 0
          this.state = res.data.modelSus.state
          // 表格
          this.tableData = res.data.susDetailList
          // 复核人
          this.vaccinaReview.reviewerName = res.data.modelSus.reviewName
          this.vaccinaReview.reviewer = res.data.modelSus.reviewer
          // 提交完成之后
          if (this.state == 1) {
            this.getModelEnd()
          }
        }
      })
    },
    // 删除
    deleteA(id) {
      this.$del('/model/susDetail/' + id).then(res => {
        this.$message.success("删除成功")
        this.$nextTick(() => {
          this.getModelTab()
        })
      })
    },
    // 提交接种信息
    submitForm(vaccination) {
      this.$refs[vaccination].validate((valid) => {
        if (valid) {
          // 保存提交状态下新增修改
          if (this.state != null) {
            let url;
            if (this.action == '新增') {
              this.$set(this.vaccination, 'subId', Number(this.id))
              this.$set(this.vaccination, 'reviewName', this.vaccinaReview.reviewerName)
              this.$set(this.vaccination, 'reviewer', this.vaccinaReview.reviewer)
              url = this.$postJson('/model/susDetail', this.vaccination)
            } else {
              // 编辑
              if (this.state == 1) {
                this.$set(this.vaccination, 'step', 0)
              }
              url = this.$putJson('/model/susDetail', this.vaccination)
            }
            url.then(res => {
              if (res.status == 200 || res.status == 201) {
                this.$message.success("提交成功")
                this.dialogVisible = false
                this.$nextTick(() => {
                  this.getModelTab()
                })
              }
            })
          } else {
            // 去建模
            if (this.action == '新增') {
              this.tableData.push(this.vaccination)
            }
            this.dialogVisible = false
          }
        }
      })
    },
    // 提交
    saveRecord(state) {
      if ($.trim(this.tableData).length == 0) {
        this.$message.warning("请先增加接种信息")
        return false;
      }
      let params = {}, url;
      if ($.trim(this.vaccinaReview.reviewer).length === 0) {
        this.$message.warning("请选择复核人")
        return false;
      }
      if (this.state == null) {
        params = {
          subId: this.id,
          state: state,
          susDetailList: this.tableData,
          reviewName: this.vaccinaReview.reviewerName,
          reviewer: this.vaccinaReview.reviewer,
        }
        url = this.$postJson('/model/sus', params)
      } else {
        // 保存状态下
        params = {
          subId: Number(this.id),
          state: state,
        }
        url = this.$putJson('/model/sus', params)
      }
      url.then(res => {
        this.$message.success("提交成功！")
        this.$router.go(-1)
      }).catch(() => {
        this.$message.error("操作失败")
      })
    },
    //提交状态
    getModelEnd() {
      this.$get('/model/susDetail/list/' + this.id).then(res => {
        if (res.status == 200) {
          /*this.state = 1
          // 表格
          this.tableData = res.data
          // 复核人
          this.vaccinaReview.reviewerName = res.data[0].reviewName*/
          // 提交状态
          let a = true
          res.data.forEach(item => {
            if (item.step != 1) {
              a = false
            }
          })
          this.endMode = a ? true : false
          if (this.status >= 22) {
            this.endMode = false
          }
          // 悬浮液配置复核
          if (this.user.userId == res.data[0].reviewer) {
            this.isCheck = true
            // 获取配置人信息
            this.preparer.time = res.data[0].createTime
            this.preparer.name = res.data[0].createName
          }

        }
      })
    },
    // 详情
    toCheck(id, checkState) {
      this.showRecord = true
      this.checkState = checkState
      this.$get('/model/susDetail/' + id).then(res => {
        if (res.status == 200) {
          this.preparationInfo = res.data
        }
      })
    },
    // 获取配置列表，保存状态下
    getModelTab() {
      this.$get('/model/susDetail/list/' + this.id).then(res => {
        if (res.status == 200) {
          this.tableData = res.data
        }
      })
    },
    // 页面显示
    changeShow(val) {
      this.showRecord = val
      this.$nextTick(() => {
        this.getModelTab()
      })
    },
    // 结束建模
    endModeDia() {
      this.$confirm('是否结束配制?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$put('/model/sus/state', {subId: this.id}).then(res => {
          if (res.status == 200) {
            this.$message.success("结束悬液配置成功")
            this.$router.go(-1)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
  },
  components: {
    "modelingTable": () => import("./modelingTable"),
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
